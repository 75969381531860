import { Model, Store, Casts, showNotification, t } from '@code-yellow/spider';
import { observable, computed, action } from 'mobx';
import { Activity, ActivityStore } from './Activity';
import TripStatus from './enums/TripStatus';
import { showErrorNotification, showSaveNotification } from '@code-yellow/spider';
import { UNIT_FTL } from './enums/Units';
import { CURRENCY_EURO } from './enums/Currencies';
import { PACKAGING_TYPE_FTL } from './enums/PackagingTypes';
import { User } from '../../../store/User';
import { Dossier } from './Dossier';
import { DATETIME_FORMAT_SHORT, DATE_FORMAT_SHORT, formatMoney, weekdays } from 'helpers';
import { DateTime } from 'luxon';
import { TripForwardingStatus } from './enums/TripForwardingStatus';
import { DriverStore, TruckingCompany } from 'react-logistics-masterdata/src';
import { IInvoiceLineLinkedObject } from 'react-core-finance/src';
import ActivityType from 'react-logistics-administration/src/store/enums/ActivityType';
import { zipCodeNumeric } from '../helpers';
import { ActivityStatus } from './enums/ActivityStatus';
export class Trip extends Model implements IInvoiceLineLinkedObject {
    static backendResourceName = 'trip';
    static omitFields = ['activitiesCount', 'swapped' ,'datetimeFrom', 'datetimeUntil', 'invoiced', 'drivers'];

    @observable id = null;
    @observable tripNumber = '';
    @observable status = TripStatus.NEW;
    @observable unit = UNIT_FTL;
    @observable salesPrice: number | null = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable remarks = '';
    @observable packagingAmount = '';
    @observable packagingType = PACKAGING_TYPE_FTL;
    @observable weight = null;
    @observable currency = CURRENCY_EURO;
    @observable adr = false;
    @observable goodsDescription = '';
    @observable cancelReason = '';

    // Forwarding
    @observable forwarded = false;
    @observable forwardingStatus: TripForwardingStatus | null = null;
    @observable forwardingPurchasePrice: number | null = null;
    @observable forwardingRemarks = '';
    @observable forwardingCharter = this.relation(TruckingCompany);

    // Annotations
    @observable activitiesCount = 0;
    @observable swappedCount = 0;
    @observable datetimeFrom: DateTime | null = null;
    @observable datetimeUntil: DateTime | null = null;

    @observable createdBy = this.relation(User);
    @observable activities = this.relation(ActivityStore);
    @observable dossier = this.relation(Dossier);
    @observable drivers = this.relation(DriverStore);

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            datetimeFrom: Casts.luxonDatetime,
            datetimeUntil: Casts.luxonDatetime,
        };
    }

    get totalKm() {
        return '--';
    }

    @computed get readOnly() {
        // We cannot check via dossier.customer, because on bulk edit we populate dossier for the trips on save
        // Also in some cases there might be no reverse relation to dossier defined in model relations(eg. we can have dossier.trips.at(0), but not trip.dossier)
        // return !this.dossier?.customer?.id;
        return false;
    }

    @computed get displayWithDossier() {
        if (this.id == null) {
            return null;
        }

        let displayName = '';

        if (this.dossier != null) {
            displayName = `${this.dossier.displayName}, ${this.tripNumber}`
        } else {
            displayName = `${this.tripNumber}`;
        }

        return displayName;
    }

    @computed get tagLabel() {
        return `O${this.tripNumber}`;
    }

    @computed get firstActivity() {
        return this.activities.models.at(0);
    }

    @computed get lastActivity() {
        return this.activities.models.at(-1);
    }

    @computed get printLocations() {
        return this.activities.map(act => act.location.toLogisticsStringShort)
    }

    @computed get editUrl() {
        return `/administration/trip/${this.id}/edit`;
    }

    @computed get isPlanned() {
        return [
            TripStatus.PLANNED,
            TripStatus.IN_PROGRESS,
            TripStatus.COMPLETED,
            TripStatus.CANCELED
        ].includes(this.status);
    }

    @computed get formattedSalesPrice() {
        if (this.currency === CURRENCY_EURO) {
            return formatMoney(this.salesPrice);
        }

        throw new Error(`Unknown currency: ${this.currency}`);
    }

    @computed get formattedSalesPriceShort() {
        return this.formattedSalesPrice.slice(0, -3);
    }


    @computed get formattedForwardingPurchasePrice(): string | null {
        if ( !this.forwardingPurchasePrice) {
            return null;
        }

        if (this.currency === CURRENCY_EURO) {
            return formatMoney(this.forwardingPurchasePrice);
        }

        throw new Error(`Unknown currency: ${this.currency}`);
    }

    @computed get formattedForwardingPurchasePriceShort() {
        return this.formattedForwardingPurchasePrice?.slice(0, -3);
    }

    @computed get forwardingMarginValue(): number | null {
        if (!this.salesPrice || !this.forwardingPurchasePrice) {
            return null;
        }

        return (this.salesPrice ?? 0) - (this.forwardingPurchasePrice ?? 0);
    }

    @computed get forwardingMarginPercent(): number | null {
        if (!this.salesPrice || !this.forwardingMarginValue) {
            return null;
        }

        return Math.round((this.forwardingMarginValue) / this.salesPrice * 100)
    }

    @computed get formattedForwardingMarginValue(): string | null {
        if (!this.salesPrice || !this.forwardingPurchasePrice) {
            return null;
        }

        if (this.currency === CURRENCY_EURO) {
            return formatMoney(this.forwardingMarginValue);
        }

        throw new Error(`Unknown currency: ${this.currency}`);
    }

    @computed get formattedForwardingMarginValueShort(): string | undefined {
        return this.formattedForwardingMarginValue?.slice(0, -3);
    }

    @computed get salesPriceValue() {
        const value = parseFloat(this.salesPrice);

        if (isNaN(value)) {
          return 0;
        }

        return value;
    }

    getDossier() {
        return this.dossier;
    }

    @computed
    get isCanceled() {
        return this.status === TripStatus.CANCELED;
    }

    @computed get statusColorOverview() {
        switch (this.status) {
            case TripStatus.ACCEPTED:
                return 'var(--blue-300)'
            case TripStatus.PLANNED:
                return 'var(--gray-300)'
            case TripStatus.IN_PROGRESS:
                return 'var(--orange-300)'
            case TripStatus.COMPLETED:
                return 'var(--green-300)'
            case TripStatus.CANCELED:
                return 'var(--red-300)'

            default:
                return 'var(--gray-100)';
        }
    }

    @computed get statusColorSemantic() {
        switch (this.status) {
            case TripStatus.ACCEPTED:
                return 'blue'
            case TripStatus.PLANNED:
                return 'grey'
            case TripStatus.IN_PROGRESS:
                return 'orange'
            case TripStatus.COMPLETED:
                return 'green'
            case TripStatus.CANCELED:
                return 'red'
            default:
                return 'grey';
        }
    }

    @computed
    get canBeCanceled() {
        const notAllowedStatuses = [TripForwardingStatus.WAITING_POD, TripForwardingStatus.FINISHED]
        return this.activities.models.every(activity => activity.canBeCanceled) && !notAllowedStatuses.includes(this.forwardingStatus);
    }

    @computed
    get canBeDeleted() {
        return this.activities.models.every(activity => activity.canBeDeleted);
    }

    @computed
    get routeString() {
        return this.activities.map((activity) => {
            if(activity.location && activity.location?.city){
                return `${activity.location.country}-${zipCodeNumeric(activity.location.zipCode.slice(0, 2))} ${activity.location.city}`;
            }
            return null;
        }).filter(activityString => activityString !== null) //filter out null values to not render empty spaces with ";" mark
        .join('; ');
    }

    @action
    cancel(reason) {
        if(this.id === null) {
            return Promise.resolve();
        }

        return this.api.post(`/trip/${this.id}/cancel_trip/`, {
                reason: reason
            }).then(() => showNotification({
                message: t('administration:trip.modal.cancel.success')
            }))
            .catch((err) => {
                showErrorNotification(err.response.data.errors);
            });
    }

    @action
    uncancel() {
        if(this.id === null) {
            return Promise.resolve();
        }

        return this.api.post(`/trip/${this.id}/uncancel_trip/`)
            .then(() => showNotification({
                message: t('administration:trip.modal.uncancel.success')
            }))
            .catch((err) => {
                showErrorNotification(err.response.data.errors);
            });
    }

    @action
    reactivate() {
        if (this?.tripNumber === '') {
            console.error('trip number is null')
        } else {
            const currentStatus = this.status;
            this.status = TripStatus.NEW;

            this.save({
                fields: ['status']
            })
            .then(showSaveNotification)
            .catch((err) => {
                this.status = currentStatus;
                showErrorNotification(err.response.data.errors);
            });
        }
    }

    @computed get isInForwarding() {
        return this.forwardingStatus != null;
    }

    formattedDate(date){
        if(date){
            return `${t(`daycy.weekDay.${weekdays.at(date?.weekday - 1)}`) + ' ' + date?.toFormat(DATETIME_FORMAT_SHORT)}`;
        }
        return null;
    }

    @computed
    get finalizedAt() {
        return this.activities.find((act)=>act.status === ActivityStatus.FINALIZED)?.statusFinalizedAt;
    }

    @action
    toForwardingToggle() {
        const status = this.isInForwarding ? null : TripForwardingStatus.TO_SELL;
        return this.changeForwardingStatus(status)
    }

    @action
    changeForwardingStatus(status: TripForwardingStatus | null) {

        if (this?.id === '') {
            console.error('trip id is null')
        } else {
            const currentForwardingStatus = this.forwardingStatus;
            this.forwardingStatus = status;
            // [TODO] temporary solution
            this.forwarded = this.isInForwarding;

            return this.save({
                fields: ['forwardingStatus', 'forwarded']
            })
                .then(showSaveNotification)
                .catch((err) => {
                    this.forwardingStatus = currentForwardingStatus;
                    showErrorNotification(err.response.data.errors);
                });
        }
    }

    async getByTripNumber(tripNumber) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const tripStore = new TripStore({
            params: {
                '.trip_number': tripNumber,
            },
            relations: this.__activeRelations,
        });

        await tripStore.fetch();
        if (tripStore.models.length > 0) {
            return tripStore.models[0];
        } else {
            return null
        }
    }

    @action
    removeActivity(activity) {
        if(this.readOnly || !this.activities) {
            return;
        }

        const nextActivities = this.activities.filter(a => a.ordering > activity.ordering);
        const nextActivity = nextActivities.at(0);

        this.activities.remove(activity);

        for(const a of nextActivities) {
            a.setInput('ordering', a.ordering - 1);
        }

        this.activities.sort();

        this.updateActivityTimes(nextActivity);
    }

    @action
    moveActivityUp(activity: Activity) {
        if(this.readOnly || !this.activities) {
            return;
        }

        const prevAcitvity = this.activities.find(a => a.ordering === activity.ordering - 1);
        if(prevAcitvity) {
            prevAcitvity.setInput('ordering', activity.ordering);
            activity.setInput('ordering', activity.ordering - 1);
        }

        this.activities.sort();

        // Trigger the event for the top activity of the two activities that we swapped in place.
        // If we move this one up then the other one gets moved down.
        this.afterActivityOrderingSwapped(activity, prevAcitvity); // Prev activity is now next activity
    }

    @action
    moveActivityDown(activity: Activity) {
        if(this.readOnly || !this.activities) {
            return;
        }

        const nextAcitvity = this.activities.find(a => a.ordering === activity.ordering + 1);
        if(nextAcitvity) {
            nextAcitvity.setInput('ordering', activity.ordering);
            activity.setInput('ordering', activity.ordering + 1);
        }

        this.activities.sort();

        // Trigger the event for the top activity of the two activities that we swapped in place.
        // If we move this one down then the other one gets moved up.
        this.afterActivityOrderingSwapped(nextAcitvity, activity); // Next activity is now prev activity
    }

    afterActivityOrderingSwapped(higherOrderActivity?: Activity, lowerOrderActivity?: Activity)  {
        // First do update for upper one, then for lower one if needed
        this.updateActivityTimes(higherOrderActivity, undefined, lowerOrderActivity);

        // If lower one is not asap, then it wont trigger update for activities beneath it in the upper call
        // So lets trigger it for the lower one manually
        if(!lowerOrderActivity?.asap) {
            this.updateActivityTimes(lowerOrderActivity, higherOrderActivity);
        }
    }

    @action
    updateActivityTimes(activity?: Activity, previousActivity?: Activity, nextActivity?: Activity ) {
        if(this.readOnly || !activity) {
            return;
        }

        // Some optimizations to avoid unnecessary looping. If there is correct next/previous activity provided lets use it instead of serching for it
        previousActivity = previousActivity?.ordering === activity.ordering - 1 ? previousActivity : this.activities.find(x=> x.ordering === activity.ordering - 1);
        nextActivity = nextActivity?.ordering === activity.ordering + 1 ? nextActivity : this.activities.find(x=> x.ordering === activity.ordering + 1);

        // Do not modify non asap activities
        if(activity.asap) {
            if(previousActivity) {
                // Get time of previous activity
                activity.setInput('orderedArrivalDatetimeFrom', previousActivity.orderedArrivalDatetimeUntil?.set({ minute: previousActivity.orderedArrivalDatetimeFrom.minute + 1 }));
                activity.setInput('orderedArrivalDatetimeUntil', activity.orderedArrivalDatetimeFrom);
            }
            else {
                // If there is no previous take current time
                activity.setInput('orderedArrivalDatetimeFrom', DateTime.local());
                activity.setInput('orderedArrivalDatetimeUntil', DateTime.local());
            }
        }
        if(nextActivity?.asap) {
            // Update times for ASAP activities right after this ASAP activity
            this.updateActivityTimes(nextActivity, activity);
        }
    }

    isFirstActivity(activity: Activity) {
        if(!activity || !this.firstActivity) {
            return false;
        }

        return this.firstActivity === activity;
    }

    isLastActivity(activity: Activity) {
        if(!activity || !this.lastActivity) {
            return false;
        }

        return this.lastActivity === activity;
    }

    @computed
    get PODVirtualPathRegex() {
        // No dossier as we might have reverse relation
        return `^/dossier/.+/activity/(?:${this.activities.map(a=>a.id).join('|') || -1})/$`;
    }

    // Invoicing stuff
    private activityToInvoiceLineDescriptionString = (activity: Activity): string => {
        return [
            activity?.location?.city,
            activity?.location?.country ? `[${activity?.location?.country}]` : '',
            activity?.orderedArrivalDatetimeFrom ? `(${ activity?.orderedArrivalDatetimeFrom?.toFormat(DATE_FORMAT_SHORT) })` : '',
        ].filter(x=>!!x).join(' ')
    }

    @observable invoiced = false;
    @observable useCustomDescription = false;


    getDescriptionFrom(): string {
        const startActivities = this.activities?.models.filter(a=>[ActivityType.LOAD, ActivityType.TRAILER_PICK_UP].includes(a.type));

        return startActivities.map(this.activityToInvoiceLineDescriptionString).filter(x=>!!x).join(', ') || '-';
    }

    getDescriptonTo(): string {
        const endActivities = this.activities?.models.filter(a=>[ActivityType.UNLOAD, ActivityType.TRAILER_DROP].includes(a.type));

        return endActivities.map(this.activityToInvoiceLineDescriptionString).filter(x=>!!x).join(', ') || '-';
    }

    getDescription(language = 'en'): string {
        return t('administration:trip.invoicing.description', { lng: language });
    }

    getAmount(): number {
        return this.salesPriceValue;
    }
}

export class TripStore extends Store<Trip> {
    Model = Trip;
    static backendResourceName = 'trip';
}
